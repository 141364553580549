import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import { useState } from "react";
import { boolean } from "yup";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import Config from "../config";

function SoleOwner(props: any) {
  const methods = useForm();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoLoading, setNoIsLoading] = useState(false);
  const [isAltLoading, setAltIsLoading] = useState(false);
  const data: any = useLoaderData();
  const navigate = useNavigate();
  console.log("currFun", data.currentFunnel);

  const onSubmit = async (values: any) => {
    let funnelChoice: any;
    if (values.buttonValue === "solo" && data.currentFunnel === 1) {
      funnelChoice = 1;
  } else if (values.buttonValue === "multi" && data.currentFunnel === 1) {
      funnelChoice = 3;
  } else if (values.buttonValue === "corp" && data.currentFunnel === 1) {
      funnelChoice = 3;
  } else if (values.buttonValue === "solo" && data.currentFunnel === 2) {
      funnelChoice = 2;
  } else if (values.buttonValue === "multi" && data.currentFunnel === 2) {
      funnelChoice = 4;
  } else if (values.buttonValue === "corp" && data.currentFunnel === 2) {
      funnelChoice = 4;
  } else {
      funnelChoice = data.currentFunnel
  }
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: ["SOLE_OWNER"],
        token: params.hash,
        singleOwner: values.buttonValue === "sole" ? true : false,
        funnelType: funnelChoice,
        currentFunnelPath: 4,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
  };

  const navigateHandler = () => {
    /* setIsLoading(true) */
    setValue("buttonValue", "sole");
    navigate(`/${params.hash}/sole-owner-information`);
  };

  const offRampHandler = () => {
    setValue("buttonValue", "multi");
    /* setNoIsLoading(true) */
    navigate(`/${params.hash}/ownership-percentage`);
  };

  const corpRampHandler = () => {
    setValue("buttonValue", "corp");
    /* setAltIsLoading(true) */
    navigate(`/${params.hash}/owner-invite`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  setFormHeader("Are you an owner of the business?");
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  setProgressHeader(true);
  const [formProgress, setFormProgress] = useAtom(progressAtom);
  setFormProgress(`w-[30%]`);
  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);
  setFormProgressText("30%");
  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
  setFormButtonShow(``);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  setformDocuBar(false);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  setFormNarrow(true);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { buttonValue: "" },
  });

  return (
    <>
      <BackButton lastPage={"finance-information"} />
      <FormProvider {...methods}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <LoadingButton />
          ) : (
            <button
              {...register("buttonValue", { value: "solo" })}
              type="submit"
              onClick={navigateHandler}
              className="btn btn-primary"
            >
              Sole Owner
            </button>
          )}

          {isNoLoading ? (
            <LoadingButton />
          ) : (
            <button
              {...register("buttonValue", { value: "multi" })}
              type="submit"
              onClick={offRampHandler}
              className="btn btn-primary"
            >
              Partial Owner
            </button>
          )}

          {isAltLoading ? (
            <LoadingButton /> 
          ) : (
          <button
            {...register("buttonValue", { value: "corp" })}
            type="submit"
            onClick={corpRampHandler}
            className="btn btn-primary"
          >
            Not the Owner
          </button>
          )}
        </form>
      </FormProvider>
    </>
  );
}

export default SoleOwner;
