enum FieldType {
  TEXT = "text",
  SELECT = "select",
  CHECKBOX = "checkbox",
  SSN = "ssn",
  EMAIL = "email",
  CURRENCY = "currency",
  DATE = "date",
  EIN = "ein",
  ADDRESS = "address",
  TEXTAREA = "textarea",
  PHONE = "phone",
}

interface BaseFieldAttributes {
  label: string;
  name: string;
  type: FieldType;
  defaultValue: any;
  validation: any;
}

interface InputFieldAttributes extends BaseFieldAttributes {
  type: FieldType.TEXT;
}

interface CheckboxFieldAttributes extends BaseFieldAttributes {
  type: FieldType.CHECKBOX;
}

// IMPORTANT: By setting the type to FieldType.SELECT, we are telling TypeScript that the 'options' property is required, so now we will get errors when the 'options' property is missing! This is a great thing!
interface SelectFieldAttributes extends BaseFieldAttributes {
  type: FieldType.SELECT;
  mode: "SELECT" | "LIST"
  options: { label: string; value: string }[];
}

interface SSNFieldAttributes extends BaseFieldAttributes {
  type: FieldType.SSN;
}

interface EmailFieldAttributes extends BaseFieldAttributes {
  type: FieldType.EMAIL;
}

interface CurrencyFieldAttributes extends BaseFieldAttributes {
  type: FieldType.CURRENCY;
}

interface DateFieldAttributes extends BaseFieldAttributes {
  type: FieldType.DATE;
}

interface EINFieldFieldAttributes extends BaseFieldAttributes {
  type: FieldType.EIN;
}

interface AddressFieldFieldAttributes extends BaseFieldAttributes {
  type: FieldType.ADDRESS;
}

interface TextAreaFieldAttributes extends BaseFieldAttributes {
  type: FieldType.TEXTAREA;
  placeholder: any;
}

interface PhoneFieldAttributes extends BaseFieldAttributes {
  type: FieldType.PHONE;
}

type FieldAttributes =
  | InputFieldAttributes
  | SelectFieldAttributes
  | CheckboxFieldAttributes
  | SSNFieldAttributes
  | EmailFieldAttributes
  | CurrencyFieldAttributes
  | DateFieldAttributes
  | EINFieldFieldAttributes
  | AddressFieldFieldAttributes
  | TextAreaFieldAttributes
  | PhoneFieldAttributes


export default FieldAttributes;
export {
  FieldType,
  type InputFieldAttributes,
  type SelectFieldAttributes,
  type CheckboxFieldAttributes,
  type SSNFieldAttributes,
  type EmailFieldAttributes,
  type CurrencyFieldAttributes,
  type DateFieldAttributes,
  type EINFieldFieldAttributes,
  type AddressFieldFieldAttributes,
  type TextAreaFieldAttributes,
  type PhoneFieldAttributes, 
};
