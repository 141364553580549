import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import lock from "../assets/lock.svg";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { SetStateAction, useEffect, useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import Config from "../config";
import arrow from "../assets/Arrow.svg"

function Identification(props: any) {
  
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [saveState, setSaveState] = useState(false);
  const [showAdditionalOwner, setShowAdditionalOwner] = useState(false);
  const [ownershipValid, setOwnershipValid] = useState(false);
  const [totalOwnership, setTotalOwnership] = useState(0);
  const [activeForm, setActiveForm] = useState(0);
  const [_, forceUpdate] = useState(false);

  const cleanOwnershipInput = (value: any) => {
    const cleanedValue = value.replace("%", "").trim();
    const parsedValue = parseFloat(cleanedValue);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }

  const formSchema: FieldAttributes[] = [
    {
      name: "secOwner",
      label: "Second Owner Name",
      type: FieldType.TEXT,
      defaultValue: data.secOwner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "secEmail",
      label: "Second Owner Email",
      type: FieldType.EMAIL,
      defaultValue: data.secEmail,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "businessAddress",
      label: "Second Owner Address",
      type: FieldType.ADDRESS,
      defaultValue: data.secAddress,
      validation: saveState ? { required: false } : { required: true }
    },
    {
      name: "secBirth",
      label: "Second Owner Date of Birth",
      type: FieldType.DATE,
      defaultValue: data.secBirth,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "secOwnership",
      label: "Second Owner Business Ownership %",
      type: FieldType.TEXT,
      defaultValue: data.secOwnership,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "secSsn",
      label: "Second Owner Social Security Number",
      type: FieldType.SSN,
      defaultValue: data.secSsn,
      validation: saveState ? { required: false } : { required: true },
      
    },
  ];
  const formSchema2: FieldAttributes[] = [
    {
      name: "thirdOwner",
      label: "Third Owner Name",
      type: FieldType.TEXT,
      defaultValue: data.thirdOwner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "thirdOwnerEmail",
      label: "Third Owner Email",
      type: FieldType.EMAIL,
      defaultValue: data.thirdOwnerEmail,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "thirdAddress",
      label: "Third Owner Address",
      type: FieldType.ADDRESS,
      defaultValue: data.thirdAddress,
      validation: saveState ? { required: false } : { required: true }
    },
    {
      name: "thirdBirth",
      label: "Third Owner Date of Birth",
      type: FieldType.DATE,
      defaultValue: data.thirdBirth,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "thirdOwnership",
      label: "Third Owner Business Ownership %",
      type: FieldType.TEXT,
      defaultValue: data.thirdOwnership,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "thirdSsn",
      label: "Third Owner Social Security Number",
      type: FieldType.SSN,
      defaultValue: data.thirdSsn,
      validation: saveState ? { required: false } : { required: true },
      
    },
  ];
  const formSchema3: FieldAttributes[] = [
    {
      name: "fourthOwner",
      label: "Fourth Owner Name",
      type: FieldType.TEXT,
      defaultValue: data.fourthOwner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "fourthOwnerEmail",
      label: "Fourth Owner Email",
      type: FieldType.EMAIL,
      defaultValue: data.fourthOwnerEmail,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "fourthdAddress",
      label: "Fourth Owner Address",
      type: FieldType.ADDRESS,
      defaultValue: data.fourthdAddress,
      validation: saveState ? { required: false } : { required: true }
    },
    {
      name: "fourthBirth",
      label: "Fourth Owner Date of Birth",
      type: FieldType.DATE,
      defaultValue: data.fourthBirth,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "fourthOwnership",
      label: "Fourth Owner Business Ownership %",
      type: FieldType.TEXT,
      defaultValue: data.fourthOwnership,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "fourthSsn",
      label: "Fourth Owner Social Security Number",
      type: FieldType.SSN,
      defaultValue: data.fourthSsn,
      validation: saveState ? { required: false } : { required: true },
      
    },
  ];

  const formSchemas = [formSchema, formSchema2, formSchema3];
  const OwnerCard = ({ ownerName, percentage, onClick  } : any) => {
    return (
      <>
        <div onClick={onClick} className="grid flex-row w-full grid-cols-[2fr_1fr_0.5fr] border items-center border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6 mobile-eq-container cursor-pointer">
          <div className="font-semibold">{ownerName}</div>
          <div className="flex justify-end ">Ownership: {percentage}%</div>
          <div className="flex justify-end">
            <img
                src={arrow}
                loading="lazy"
                alt="arrow icon"
              />
          </div>
        </div>
      </>
    )
  }

  const FirstOwnerCard = ({ ownerName, percentage, onClick  } : any) => {
    return (
      <>
        <div onClick={onClick} className="grid flex-row w-full grid-cols-[2fr_1fr_0.5fr] border items-center border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6 mobile-eq-container">
          <div className="font-semibold">{ownerName}</div>
          <div className="flex justify-end ">Ownership: {percentage}%</div>
        </div>
      </>
    )
  }

  const [isLoading, setIsLoading] = useState(false);

  const handleOwnerCardClick = (formNumber: number) => {
    console.log(`OwnerCard clicked, setting active form to: ${formNumber}`);
    setActiveForm(formNumber);
};
  let saved: boolean

  const onSubmitHandler = async (values: any) => {
    
    const saveApplicationStep = async (values: any) => {
      if (!saveState && methods.formState.isValid) {
        setIsLoading(true);
      }
      const payload = {
        Status: ["OWNER_INFO2"],
        token: params.hash,
        secOwner: values.secOwner,
        secEmail: values.secEmail,
        secAddress: values.businessAddress,
        secBirth: values.secBirth,
        secOwnership: values.secOwnership,
        secSsn: values.secSsn,
        thirdOwnerName: values.thirdOwner,
        thirdOwnerEmail: values.thirdOwnerEmail,
        thirdAddress: values.thirdAddress,
        thirdBirth: values.thirdBirth,
        thirdOwnership: values.thirdOwnership,
        thirdSsn: values.thirdSsn,
        fourthOwnerName: values.fourthOwner,
        fourthOwnerEmail: values.fourthOwnerEmail,
        fourthdAddress: values.fourthdAddress,
        fourthBirth: values.fourthBirth,
        fourthOwnership: values.fourthOwnership,
        fourthSsn: values.fourthSsn,
        currentFunnelPath: (data.currentFunnel === 1 || data.currentFunnel === 2) ? (saveState ? 5 : 6) : (saveState ? 6 : 7)
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    saved = await saveApplicationStep(values);
    const secOwnership = parseFloat(methods.getValues('secOwnership')) || parseFloat(data.secOwnership) || 0;
    const thirdOwnership = parseFloat(methods.getValues('thirdOwnership')) || parseFloat(data.thirdOwnership) || 0;
    const fourthOwnership = parseFloat(methods.getValues('fourthOwnership')) || parseFloat(data.fourthOwnership) || 0;
    const computedTotalOwnership = parseFloat(data.majorityOwnership || 0) + secOwnership + thirdOwnership + fourthOwnership;

    console.log("Total ownership calculated: ", computedTotalOwnership);
    setTotalOwnership(computedTotalOwnership);
    if (computedTotalOwnership >= 80) {
        navigate(`/${params.hash}/sign`);
    } else {
        window.location.reload()
    }
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    await methods.trigger();
    setSaveState(false);
    if (!methods.formState.isValid) {
      console.log("Form validation failed.");
      return;
    }
  
    const secOwnership = parseFloat(methods.getValues('secOwnership')) || parseFloat(data.secOwnership) || 0;
    const thirdOwnership = parseFloat(methods.getValues('thirdOwnership')) || parseFloat(data.thirdOwnership) || 0;
    const fourthOwnership = parseFloat(methods.getValues('fourthOwnership')) || parseFloat(data.fourthOwnership) || 0;
    const computedTotalOwnership = parseFloat(data.majorityOwnership || 0) + secOwnership + thirdOwnership + fourthOwnership;

    console.log("Total ownership calculated: ", computedTotalOwnership);
    setTotalOwnership(computedTotalOwnership);

    let nextActiveForm = activeForm;
    if (secOwnership > 0 && !data.secOwner) nextActiveForm = Math.max(nextActiveForm, 1);
    if (thirdOwnership > 0 && !data.thirdOwner) nextActiveForm = Math.max(nextActiveForm, 2);
    if (fourthOwnership > 0 && !data.fourthOwner) nextActiveForm = Math.max(nextActiveForm, 3);

    setActiveForm(nextActiveForm);
    forceUpdate(u => !u);

    if (computedTotalOwnership >= 80) {
      navigate(`/${params.hash}/sign`);
  } else {
      //saved ? window.location.reload() : null
      console.log("Active Form after Navigate Handler: ", nextActiveForm);
  }
};

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    let initialOwnership = parseFloat(data.majorityOwnership) || 0;
    const ownershipValues = [data.secOwnership, data.thirdOwnership, data.fourthOwnership];
    let highestFormWithData = 0;
    ownershipValues.forEach((ownership, index) => {
      if (parseFloat(ownership)) {
        initialOwnership += parseFloat(ownership);
        highestFormWithData = index + 1;
      }
    });
    setTotalOwnership(initialOwnership);
    setShowAdditionalOwner(true);

    if (initialOwnership <= 80 && highestFormWithData < ownershipValues.length) {
      console.log("highestFormWithData + 1 useEffect", highestFormWithData + 1)
      console.log("activeForm", activeForm)
      setActiveForm(highestFormWithData + 1);
    } else {
      console.log("highestFormWithData else useEffect", highestFormWithData)
      console.log("activeForm else", activeForm)
      setActiveForm(highestFormWithData);
    }

    setFormHeader("Additional information required");
    setProgressHeader(true);
    setFormProgress(`w-[60%]`);
    setFormProgressText("60%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
}, []);

  return (
    <>
      <BackButton 
        lastPage={"sole-owner-information"} />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)} key={activeForm}>
          <p className="text-[#525351]">
            For the Credit Application to be successful, 
            we need the ownership details of the owners to reflect at least 80% ownership.
          </p>
          <div className={`flex flex-row items-center rounded p-3 border-2 ${totalOwnership > 80 ? 'border-green-500' : 'border-red-500'}`}>
            <p className="text-sm text-[#525351]">
              Total Ownership Percentage: <span className={`semibold ${totalOwnership > 80 ? 'text-green-500' : 'text-red-500'}`}>{totalOwnership}%</span>
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <FirstOwnerCard ownerName={`${data.firstName} ${data.lastName}`} percentage={data.majorityOwnership} className="order-1"/>
            {data.secOwnership && (
              <OwnerCard ownerName={data.secOwner} percentage={data.secOwnership} onClick={() => handleOwnerCardClick(1)} className="order-1"/>
            )}
            {data.thirdOwnership && (
              <OwnerCard ownerName={data.thirdOwner} percentage={data.thirdOwnership} onClick={() => handleOwnerCardClick(2)} className="order-1"/>
            )}
            {data.fourthOwnership && (
              <OwnerCard ownerName={data.fourthOwner} percentage={data.fourthOwnership} onClick={() => handleOwnerCardClick(3)} className="order-1"/>
            )}
            <div className="flex flex-col gap-4 order-2">
              {activeForm === 1 && formSchema.map((field, index) => <Field key={index} {...field} />)}
              {activeForm === 2 && formSchema2.map((field, index) => <Field key={index} {...field} />)}
              {activeForm === 3 && formSchema3.map((field, index) => <Field key={index} {...field} />)}
            </div>
          </div>
          <div className="flex flex-row items-center bg-[#F6F5F5] rounded p-3">
            <img className="m-4" src={lock} alt="lock svg" />
            <p className="text-sm text-[#525351]">
              We use bank-level security to protect your data. However, if you
              prefer to talk to your sales representative call us now at{" "}
              <a href="tel:18003293375">
                <u>1 800 329 3375</u>
              </a>
            </p>
          </div>
          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Next"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default Identification;
