import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import React from "react";
import Config from "../config";

function CoOwnerInvite(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [saveState, setSaveState] = useState(false);

  const formSchema1A: FieldAttributes[] = [
    {
      name: "owner1FirstName",
      label: "First Name",
      type: FieldType.TEXT,
      defaultValue: data.secOwnerFirstName,
      validation: { required: true },
    },
    {
      name: "owner1LastName",
      label: "Last Name",
      type: FieldType.TEXT,
      defaultValue: data.secOwnerLastName,
      validation: { required: true },
    },
  ];
  const formSchema1B: FieldAttributes[] = [
    {
      name: "owner1Email",
      label: "Email",
      type: FieldType.EMAIL,
      defaultValue: data.secEmail,
      validation: { required: true },
    },
  ];

  const formSchema2A: FieldAttributes[] = [
    {
      name: "owner2FirstName",
      label: "First Name",
      type: FieldType.TEXT,
      defaultValue: data.thirdOwnerFirstName,
      validation: { required: false },
    },
    {
      name: "owner2LastName",
      label: "Last Name",
      type: FieldType.TEXT,
      defaultValue: data.thirdOwnerLastName,
      validation: { required: false },
    },
  ];
  const formSchema2B: FieldAttributes[] = [
    {
      name: "owner2Email",
      label: "Email",
      type: FieldType.EMAIL,
      defaultValue: data.thirdOwnerEmail,
      validation: { required: false },
    },
  ];

  const formSchema3A: FieldAttributes[] = [
    {
      name: "owner3FirstName",
      label: "First Name",
      type: FieldType.TEXT,
      defaultValue: data.fourthOwnerFirstName,
      validation: { required: false },
    },
    {
      name: "owner3LastName",
      label: "Last Name",
      type: FieldType.TEXT,
      defaultValue: data.fourthOwnerLastName,
      validation: { required: false },
    },
  ];
  const formSchema3B: FieldAttributes[] = [
    {
      name: "owner3Email",
      label: "Email",
      type: FieldType.EMAIL,
      defaultValue: data.fourthOwnerEmail,
      validation: { required: false },
    },
  ];

  const formSchema4A: FieldAttributes[] = [
    {
      name: "owner4FirstName",
      label: "First Name",
      type: FieldType.TEXT,
      defaultValue: data.fifthOwnerFirstName,
      validation: { required: false },
    },
    {
      name: "owner4LastName",
      label: "Last Name",
      type: FieldType.TEXT,
      defaultValue: data.fifthOwnerLastName,
      validation: { required: false },
    },
  ];

  const formSchema4B: FieldAttributes[] = [
    {
      name: "owner4Email",
      label: "Email",
      type: FieldType.EMAIL,
      defaultValue: data.fifthOwnerEmail,
      validation: { required: false },
    },
  ];

  /*     const formSchema5A: FieldAttributes[] = [
        {
            name: "owner5FirstName",
            label: "First Name",
            type: FieldType.TEXT,
            defaultValue: ,
            validation: { required: false }
        },
        {
            name: "owner5LastName",
            label: "Last Name",
            type: FieldType.TEXT,
            defaultValue: ,
            validation: { required: false }
        }
    ]; */
  /* 
    const formSchema5B: FieldAttributes[] = [
        {
            name: "owner5Email",
            label: "Email",
            type: FieldType.EMAIL,
            defaultValue: ,
            validation: { required: false }
          }
    ]; */

  const [isLoading, setIsLoading] = useState(false);
  const [formCount, setFormCount] = useState(data.numberOfOwners ? data.numberOfOwners : 0);
  console.log("formCount", formCount);

  const onSubmitHandler = async (values: any) => {
    const ownerName1 =
      values.owner1FirstName && values.owner1LastName
        ? `${values.owner1FirstName} ${values.owner1LastName}`
        : "";
    const ownerName2 =
      values.owner2FirstName && values.owner2LastName
        ? `${values.owner2FirstName} ${values.owner2LastName}`
        : "";
    const ownerName3 =
      values.owner3FirstName && values.owner3LastName
        ? `${values.owner3FirstName} ${values.owner3LastName}`
        : "";
    //const ownerName4 = (values.owner4FirstName + ' ' + values.owner4LastName)
    const ownerName4 =
      values.owner4FirstName && values.owner4LastName
        ? `${values.owner4FirstName} ${values.owner4LastName}`
        : "";
    const ownerName5 = values.owner5FirstName + " " + values.owner5LastName;

    console.log("owner1", ownerName1);
    console.log("owner2", ownerName2);
    console.log("owner3", ownerName3);
    console.log("owner4", ownerName4);
    

    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: saveState ? ["CO-OWNERS"] : ["DOCUSIGN"],
        token: params.hash,
        currentFunnelPath: 7, 
        numberOfOwners: formCount ? formCount.toString() : 1,
        majorityEmail: data.contactEmail,
        secOwner: ownerName1,
        secEmail: values.owner1Email,
        thirdOwnerName: ownerName2,
        thirdOwnerEmail: values.owner2Email,
        fourthOwnerName: ownerName3,
        fourthOwnerEmail: values.owner3Email,
        fifthOwnerName: ownerName4,
        fifthOwnerEmail: values.owner4Email,
        isMobile: data.isMobile,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    console.log(values);
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    await methods.trigger();
    console.log(formCount);
    PopUpToggler();
  };

  const navigateHandler = async () => {
    setSaveState(false);
    await methods.trigger();
    if (methods.formState.isValid) {
      setIsLoading(true);
      await onSubmitHandler(methods.getValues());
      navigate(`/${params.hash}/number-of-owners-thank-you`);
    }
  };

  const ownerHandler = () => {
    setFormCount(parseInt(formCount) + 1);
    console.log(formCount);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setFormHeader("Invite your co-owners");
    setProgressHeader(true);
    setFormProgress(`w-9/12`);
    setFormProgressText("70%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
  }, []);

  return (
    <>
      <BackButton lastPage={"sole-owner-information-part2"} />
      <FormProvider {...methods}>
        <p className="text-base leading-6 text-[#171718]">
          Add every owner other than yourself with 20% or greater ownership{" "}
          <span className="text-[#FA5252]">*</span>
        </p>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          {formCount >= 1 ? (
            <>
              <div className="flex flex-row mt-16">
                <div className="font-medium text-sm leading-[14px] tracking-wider uppercase mr-4 text-[#171718] flex-none order-none grow-0">
                  Co-Owner #1
                </div>
                <div className="h-[1.22px] bg-neutral-300 self-center w-full" />
              </div>
              <div className="flex flex-row justify-between gap-8 footer-simpl">
                {formSchema1A.map((field, index) => (
                  <Field key={index} {...field} />
                ))}
              </div>
              {formSchema1B.map((field, index) => (
                <Field key={index} {...field} />
              ))}
            </>
          ) : (
            <></>
          )}
          {formCount >= 2 ? (
            <>
              <div className="flex flex-row mt-16">
                <div className="font-medium text-sm leading-[14px] tracking-wider uppercase mr-4 text-[#171718] flex-none order-none grow-0">
                  Co-Owner #2
                </div>
                <div className="h-[1.22px] bg-neutral-300 self-center w-full" />
              </div>
              <div className="flex flex-row justify-between gap-8 footer-simpl">
                {formSchema2A.map((field, index) => (
                  <Field key={index} {...field} />
                ))}
              </div>
              {formSchema2B.map((field, index) => (
                <Field key={index} {...field} />
              ))}
            </>
          ) : (
            <></>
          )}
          {formCount >= 3 ? (
            <>
              <div className="flex flex-row mt-16">
                <div className="font-medium text-sm leading-[14px] tracking-wider uppercase mr-4 text-[#171718] flex-none order-none grow-0">
                  Co-Owner #3
                </div>
                <div className="h-[1.22px] bg-neutral-300 self-center w-full" />
              </div>
              <div className="flex flex-row justify-between gap-8 footer-simpl">
                {formSchema3A.map((field, index) => (
                  <Field key={index} {...field} />
                ))}
              </div>
              {formSchema3B.map((field, index) => (
                <Field key={index} {...field} />
              ))}
            </>
          ) : (
            <></>
          )}
          {formCount >= 4 ? (
            <>
              <div className="flex flex-row mt-16">
                <div className="font-medium text-sm leading-[14px] tracking-wider uppercase mr-4 text-[#171718] flex-none order-none grow-0">
                  Co-Owner #4
                </div>
                <div className="h-[1.22px] bg-neutral-300 self-center w-full" />
              </div>
              <div className="flex flex-row justify-between gap-8 footer-simpl">
                {formSchema4A.map((field, index) => (
                  <Field key={index} {...field} />
                ))}
              </div>
              {formSchema4B.map((field, index) => (
                <Field key={index} {...field} />
              ))}
            </>
          ) : (
            <></>
          )}
          {/*                 { formCount >= 5 ? ( <>
                <div className="flex flex-row mt-16"><div className="font-medium text-sm leading-[14px] tracking-wider uppercase mr-4 text-[#171718] flex-none order-none grow-0">Co-Owner #5</div><div className="h-[1.22px] bg-neutral-300 self-center w-full"/></div>
                <div className="flex flex-row justify-between gap-8 footer-simpl">
                    {formSchema5A.map((field, index) => (
                        <Field key={index} {...field} />
                    ))}</div>
                {formSchema5B.map((field, index) => (
                    <Field key={index} {...field} />
                ))}
                </>
                ) : (<></>)} */}
          <button
            onClick={ownerHandler}
            className="btn-add mt-4"
            type="button"
            style={{ display: formCount >= 4 ? "none" : "inline" }}
          >
            + Add Owner
          </button>
          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Next"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default CoOwnerInvite;
