import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import sign from "../../../assets/Icon-chevron-left.svg";
import "../../../styles/style.css";
import { atom, useAtom } from 'jotai';
import { useEffect } from "react";

export const bbuttonAtom = atom(``)


function BackButton({ lastPage }: { lastPage: string }) {
  const params = useParams();
  const navigate = useNavigate();
  const [formButtonShow] = useAtom(bbuttonAtom);

  const handleNavigation = () => {
    navigate(`/${params.hash}/${lastPage}`);
  };

  useEffect(() => {
    window.addEventListener("popstate", handleNavigation);
    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, []);

  return (
    <div className={`relative left-[-4rem] top-[-7rem] w-full max-w-screen-sm mx-auto lettering ${formButtonShow}`}>
      <div 
        onClick={handleNavigation}
        className="absolute flex left-[-7rem] w-28 items-center gap-x-3 gap-y-3 text-[#171718] font-semibold no-underline pl-3 pr-6 py-3 top-8 cursor-pointer"
      >
        <img src={sign} loading="lazy" alt="back icon" />
        <span>Back</span>
      </div>
    </div>
  );
}

export default BackButton;