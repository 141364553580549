import React from "react";
import { useFormContext } from "react-hook-form";
import { PhoneFieldAttributes } from "../../models/FieldAttributes";

const PhoneField: React.FC<PhoneFieldAttributes> = ({
  label,
  name,
  defaultValue,
  validation,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch
  } = useFormContext();

  // Format phone number as user types
  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/[^\d]/g, '');
    
    // Format the number based on length
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  // Handle input changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(event.target.value);
    setValue(name, formatted, { shouldValidate: true });
  };

  // Phone validation pattern
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  // Combine provided validation with phone specific validation
  const phoneValidation = {
    ...validation,
    pattern: {
      value: phoneRegex,
      message: "Please enter a valid phone number: (XXX) XXX-XXXX"
    }
  };

  return (
    <div className="relative w-full mb-2">
      <label 
        htmlFor={name} 
        {...(validation.required ? { 'data-required': 'true'} : {})} 
        className="leading-6 pb-2 text-[#171718] font-semibold pl-0"
      >
        {label}
      </label>
      <input
        className={`rounded w-full shadow-[inset_1px_1px_3px_0_rgba(0,0,0,0.06)] min-h-[3rem] mb-0 px-4 py-2 border-solid border-2 ${
          errors[name] ? 'border-[#FA5252] bg-[#FFF5F5]' : 'border-neutral-300'
        }`}
        defaultValue={formatPhoneNumber(defaultValue || '')}
        {...register(name, phoneValidation)}
        onChange={handleChange}
        id={name}
        type="tel"
        placeholder="(555) 555-5555"
        maxLength={14}
      />
      {errors[name] && (
        <span className="absolute left-0 top-full min-h-[0.25rem] text-[#FA5252] text-sm">
        </span>
      )}
    </div>
  );
};

export default PhoneField;