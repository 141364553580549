import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import Config from "../config";

function EntityTypeSelection() {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [saveState, setSaveState] = useState(true);
  console.log("data", data)

  const formSchema: FieldAttributes[] = [
    {
      name: "entityType",
      label: "What type of entity is your business?",
      type: FieldType.SELECT,
      defaultValue: data.entityType,
      mode: "SELECT",
      validation: saveState ? { required: false } : { required: true },
      options: [
        { label: "Corporation", value: "corporation" },
        { label: "Sole Proprietor", value: "soleProprietor" },
        { label: "Partnership", value: "partnership" },
        { label: "LLC", value: "llc" },
        { label: "Government", value: "government" },
        { label: "Nonprofit", value: "nonprofit" },
        { label: "Tribal", value: "tribal" },
        { label: "Other", value: "other" }
      ]
    }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    if (!saveState && methods.formState.isValid) {
      setIsLoading(true);
    }

    if (methods.formState.isValid /* && saved */) {
      navigate(`/${params.hash}/business-information`);
    }
    
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: ["ENTITY_TYPE"],
        token: params.hash,
        entityType: values.entityType,
        currentFunnelPath: saveState ? 1 : 2
      };
      
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    };

    saveApplicationStep(values);
  }

  const saveHandler = async () => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async () => {
    setSaveState(false);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  const [formProgress, setFormProgress] = useAtom(progressAtom);
  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setFormHeader("Let's get started with your application");
    setProgressHeader(true);
    setFormProgress("w-1/4");
    setFormProgressText("10%");
    setformDocuBar(false);
    setFormNarrow(true);
    methods.trigger();
  }, []);

  return (
    <FormProvider {...methods}>
      <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
        {formSchema.map((field, index) => (
          <Field key={index} {...field} />
        ))}
        <ButtonContainer
          button1={"Save & Finish Later"}
          button2={"Next"}
          loading={isLoading}
          navigate={navigateHandler}
          save={saveHandler}
        />
      </form>
    </FormProvider>
  );
}

export default EntityTypeSelection;